import styles from "./styles.module.css";
import Dialog from "@material-ui/core/Dialog";
import closeButton from '../../assets/svg/closeBtn.svg'
import openBook from '../../assets/smiles/openBook.svg'
import arrowPhone from '../../assets/smiles/arrowPhone.svg'
import heart from '../../assets/smiles/heart.svg'
import { Button } from "../shared";
import { BlockSub } from './BlockSub'
import sad from '../../assets/smiles/sad.svg'
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSubModal } from '../../modules/controllers'
import { postReq } from "../../utils";
import { pathsApi } from "../../consts";
import { useHistory } from 'react-router-dom'

const infoContent = [
    {
        img: openBook,
        text: `Ты получишь доступ к решениям всех заданий из твоих учебников без ограничений`
    },
    {
        img: heart,
        text: `Ты поддержишь нас, и мы сможем сделать еще больше предметов и классных роликов!`
    },
]

const subs = {
    2: 'monthly',
    3: 'annually'
}

export const content = [
    {
        id: 1,
        img: sad,
        title: 'Без подписки',
        info: `Ограниченный доступ к материалам`,
        description: '',
        price: '0'
    },
    {
        id: 2,
        title: 'На месяц',
        info: `Полный доступ ко всем материалам I и II четверти на 30 дней`,
        price: '990',
        value: subs[2]
    },
    {
        id: 3,
        title: 'Навсегда',
        info: `Полный доступ ко всем материалам I и II четверти навсегда`,
        price: '2990',
        value: subs[3]
    },
]


export const ModalSub = () => {
    const [activeId, setActiveId] = useState(null);
    const [fetch, setFetch] = useState(false);
    const user = useSelector(state => state?.user?.user)
    const dispatch = useDispatch();
    const open = useSelector(state => state.dataControllers.isOpenModalSub)
    const subscribeInfoAmount = user?.subscribeInfo?.amount?.toString()

    const isNext = (activeId && subscribeInfoAmount !== content.find((item) => item.id === activeId).price.replace(/\s+/g, '')) || true
    const handleClick = async () => {
        window.ym(97358459,'идентификатор: form752228466',333123385)
        setFetch(true)
        if (activeId) {
            const data = await postReq(pathsApi.purchase, {
                purchaseCycle: subs[activeId]
            })
            setFetch(false)
            if (data?.formUrl) {
                window.location.replace(data?.formUrl);
            }
        }
    }

    window.ym(97358459,'идентификатор: form750912271',333122829)

    return (
        <Dialog
            open={open}
            onClose={() => dispatch(closeSubModal())}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                style: {
                    padding: 0,
                    borderRadius: 16,
                    maxWidth: 780,
                }
            }}
        >
            <div className={styles.contentModal}>
                <h1 className={styles.title}>Оформить подписку</h1>
                <span className={styles.description}>Получи полный доступ к объяснениям всех заданий из твоих учебников</span>
                <img className={styles.closeBtn} src={closeButton} alt='/' onClick={() => dispatch(closeSubModal())} />
                <BlockSub
                    content={content}
                    onClickItem={setActiveId}
                    activeId={activeId}
                    subscribeInfoAmount={subscribeInfoAmount}
                    status={user?.subscribeInfo?.status}
                />
                <div className={styles.controllers}>
                    <Button width={240} disabled={!isNext || fetch} onClick={handleClick}>
                        Оформить подписку
                    </Button>
                </div>
                <div className={styles.infoBlock}>
                    <h2 className={styles.infoTitle}>Что дает подписка?</h2>
                    {
                        infoContent.map((item, index) => (
                            <div key={index} className={styles.infoContainer}>
                                <img src={item.img} alt='/' />
                                <p className={styles.textInfo}>{item.text}</p>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.divider} />
                <p className={styles.info}>
                    Оплата осуществляется при подтверждении подписки. Вы можете отменить подписку в любое время.
                    Управление подписками и удаление осуществляется в настройках профиля. Подписка автоматически продлевается,
                    если не была отменена не менее чем за 24 часа до окончания текущего срока действия.
                </p>
                <a href="https://app.izidz.com/documents/privacy-policy.pdf" target='_blank' className={styles.link}>Пользовательское соглашение</a> и <a href={'https://app.izidz.com/documents/eula.pdf'} target='_blank'  className={styles.link}>правила использования</a>
                <a href='/' className={styles.linkSecond}>Восстановить покупки</a>
            </div>
        </Dialog>
    )
}
