import styles from "./authorization.module.css";
import { Button, Input } from "@shared";
import React, { useState } from "react";
import cn from 'classnames'
import { useHistory } from "react-router-dom";
import { navigationLink } from '@consts'
import { putReq } from "../../utils";
import { pathsApi } from "../../consts";
import { useClassList } from "../../hooks";
import dayjs from "dayjs";

const CLASSES = new Array(11).fill(0).map((item, index) => `${index + 1} класс`);

export const ThirdScreen = ({ userId }) => {
    const [name, setName] = useState('');
    const [activeClass, setClass] = useState(5);
    const [error, setError] = useState(false);
    const [fetch, setFetch] = useState(false);
    const history = useHistory();
    const classesList = useClassList()
    const handleChange = (event) => {
        setName(event.target.value);
    };
    const isNext = !!name && !!activeClass;
    const isClassView = (date) => {
        if (date) {
            return dayjs().isBefore(dayjs.unix(date))
        } else {
            return false
        }
    }
    const handleClick = async () => {
        const data = await putReq(`${pathsApi.users}/${userId}`, {
            username: name,
            classNumber: activeClass,
        })

        setFetch(false)
        if (data && data.username) {
            history.push(`${navigationLink.addBook}`)
        } else {
            setError(true)
        }
    }

    return (
        <>
            <h1 className={styles.title}>Давай знакомиться!</h1>
            <div className={styles.inputBlock}>
                <Input
                    label="Как тебя зовут?"
                    value={name}
                    onChange={handleChange}
                />
            </div>
            
            
                    <Button width={240} onClick={handleClick} disabled={!isNext}>
                        Дальше
                    </Button>
            
            
        </>
    );
}

export default ThirdScreen;
