import styles from './styles.module.css';
import bigDone from '../../assets/svg/bigDone.svg'
import bigError from '../../assets/svg/bigError.svg'
import loader from '../../assets/svg/loader.svg'
import { Button } from "../../components/shared";
import { useHistory, useLocation } from 'react-router-dom'
import { navigationLink } from '@consts'
import { useEffect, useState } from "react";
import { postReq } from "../../utils";
import { pathsApi } from "../../consts";
import {useDispatch, useSelector} from "react-redux";
import cn from 'classnames'
import {fetchUserById} from "../../modules/user";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const CheckPurchase = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [fetch, setFetch] = useState(false);
    const [response, setResponse] = useState(null)
    const [isUserLoad, setUserLoad] = useState(false)
    const user = useSelector(state => state?.user?.user)
    let query = useQuery();

    useEffect(async () => {
        if (user?.id && !isUserLoad) {
            setFetch(true)
            const data = await postReq(pathsApi.purchaseCheck, {
                orderId: query.get("orderId"),
                clientId: query.get("userId"),
            })
            setFetch(false)
            setResponse(data)
            dispatch(fetchUserById())
            setUserLoad(true)
            if (response.message) {
                window.ym(97358459,'reachGoal',332924124)
            }

        }
    }, [user])

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Оформить подписку</h1>
            {
                fetch ? (
                    <img className={cn(styles.image, styles.animation)} alt='/' src={loader} />
                ) : (
                    <img className={styles.image} alt='/' src={!response?.error ? bigDone : bigError} />
                )
            }
            {
                response?.error && (
                    <>
                        <div className={styles.boldText}>Что-то пошло не так...</div>
                        <Button
                            width={200}
                            onClick={() => history.push(navigationLink.mainboard)}
                            opacityButton
                            color={'rgba(255, 107, 85, 0.08)'}
                        >
                            Попробовать снова
                        </Button>
                        <p className={styles.text}>
                            Если ничего не происходит, напишите на <br /> <a>example@gmail.com</a> или позвоните <a>8 800 555 25 25</a>
                        </p>
                    </>
                )
            }
            {
                response?.message && (
                    <>
                        <div className={styles.boldText}>Готово! <br /> Подписка оформлена</div>
                        <Button width={160} onClick={() => history.push(navigationLink.mainboard)}>На главный</Button>
                    </>
                )
            }
            {
                fetch && (
                    <>
                        <div className={styles.boldText}>Обработка платежа</div>
                        <Button
                            width={200}
                            onClick={() => history.push(navigationLink.mainboard)}
                            opacityButton
                            color={'rgba(255, 107, 85, 0.08)'}
                        >
                            Попробовать снова
                        </Button>
                        <p className={styles.text}>
                            Ждём ответа от банка, обычно это занимает <br /> несколько минут.
                            <br />
                            <br />
                            Если ничего не происходит, напишите на <br /> <a>example@gmail.com</a> или позвоните <a>8 800 555 25 25</a>
                        </p>
                    </>
                )
            }
        </div>
    );
}

export default CheckPurchase;
