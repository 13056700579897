import { useEffect, useState } from 'react'
import { SmsField } from '@shared'
import styles from "./authorization.module.css";
import { useConfirmPhone, useSetPhone, useInterval } from './Authorization.hooks'
import {useDispatch, useSelector} from "react-redux";
import { clearPhone } from '../../modules/user'
import { useHistory } from 'react-router-dom';
import {navigationLink} from "../../consts";

const MAX_SMS_LENGTH = 4;
const TIME_CODE_REFRESH = 10;
const DELAY = 1000

export const SecondScreen = ({ onNextScreen, onPrevScreen, setUserId }) => {
    const phone = useSelector(state => state?.user?.phone)
    const [smsText, setSmsText] = useState('0000');
    const [seconds, setSeconds] = useState(TIME_CODE_REFRESH);
    const history = useHistory()
    const [errorCode, onSubmitConfirmation] = useConfirmPhone(phone, onNextScreen, smsText, setUserId);
    const [errorPhone, updateCode] = useSetPhone(phone, onNextScreen, true)
    const dispatch = useDispatch()

    useInterval(
        () => {
            setSeconds(seconds - 1)
        },
        seconds ? DELAY : null,
    )

    useEffect(() => {
        if (smsText.length === MAX_SMS_LENGTH) {
            onSubmitConfirmation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smsText, onNextScreen])

    const handleUpdateCode = () => {
        if (!seconds) {
            updateCode();
            setSeconds(TIME_CODE_REFRESH);
        }
    }
    const onHandlerClearPhone = () => {
        dispatch(clearPhone())
        history.push(navigationLink.authorization)
        onPrevScreen();
    }

    const secondsText = seconds ? ` через ${seconds}` : ''
    return (
        <>
            <h1 className={styles.title}>СМС-код</h1>
            <p className={styles.description}>
                Мы выслали СМС-код на номер<br />
                <b>{phone}</b> для подтверждения
            </p>
            {
                errorPhone && <div className={styles.orangeTextButtonSmall}>Что-то пошло не так</div>
            }
            {
                errorCode && <div className={styles.orangeTextButtonSmall}>Код неверный</div>
            }
            <div className={styles.inputBlock}>
                <SmsField
                    value={smsText}
                    setValue={setSmsText}
                    maxSmsLength={MAX_SMS_LENGTH}
                />
            </div>
            <div className={styles.orangeTextButton} onClick={handleUpdateCode}>Выслать код повторно{secondsText}</div>
            <div className={styles.orangeTextButton} onClick={onHandlerClearPhone}>Изменить номер</div>
        </>
    );
}

export default SecondScreen;
