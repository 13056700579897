import styles from "./block.module.css";
import orange_check from '../../assets/svg/orange_check.svg'
import cn from 'classnames'

export const BlockSub = ({ content, activeId, onClickItem, subscribeInfoAmount, status }) => {
    const price = status === 'Success' ? subscribeInfoAmount || '0' : '0'
    const currentSubscription = content.findLast((item) => item?.price?.replace(/\s+/g, '') === price).id

    const handleClick = (id) => {
        if (id !== 1) {
            onClickItem(id)
        } else {
            onClickItem(null)
        }

    }

    return (
        <div className={styles.content}>
            {
                content.map((item) => (
                    <div
                        key={item.id}
                        className={cn(styles.block, { [styles.activeBlock]: activeId === item.id })}
                        onClick={() => handleClick(item.id)}
                    >
                        <div className={styles.topBlock}>
                            {
                                item.id !== 1 && (
                                    <div className={styles.checkContainer}>
                                        {
                                            (activeId === item.id) || (item.id === currentSubscription) ? (
                                                <img alt="/" src={orange_check} />
                                            ) : (
                                                <div className={styles.check} />
                                            )
                                        }
                                    </div>
                                )
                            }
                            <h3 className={styles.title}>
                                {item.title}
                                {item?.img && <img src={item.img} alt='/' />}
                            </h3>
                            <div className={styles.price}>{item.price} ₽</div>
                            <p className={styles.info}>{item.info}</p>
                        </div>
                        <div className={cn(styles.description, { [styles.currentSub]: currentSubscription === item.id })}>
                            {currentSubscription === item.id ? 'Текущая подписка' : item.description}</div>
                    </div>
                ))
            }
        </div>
    )
}
