import styles from './authorization.module.css'
import { ReactComponent as Arrow } from '@assets/svg/arrow.svg';
import { useDispatch, useSelector } from "react-redux";
import { setPhone } from '../../modules/user'
import { Button, MaskInput } from "@shared";
import { useSetPhone, useGetUdid } from './Authorization.hooks'
import { useEffect } from "react";
import hi from "../../assets/smiles/hi.svg";
import {useHistory, useParams} from "react-router-dom";
import {navigationLink} from "../../consts";
import ym from "react-yandex-metrika";

let regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

export const FirstScreen = ({ onNextScreen }) => {
    const phone = useSelector(state => state?.user?.phone)
    const [error, handleNext] = useSetPhone(phone, onNextScreen)
    const history = useHistory()
    const { number } = useParams()
    useEffect(() => {
        if (number) {
            dispatch(setPhone(`+7${number}`));
        }
    }, [])

    useEffect(() => {
        if (number && error) {
            history.push(navigationLink.authorization);
        }
    }, [error])

    useEffect(() => {
        if (phone && number && regex.test(phone)) {
            handleNext();
        }
    }, [phone])
    const dispatch = useDispatch();

    const clickNext = () => {
        window.ym(97358459,'reachGoal','send_number')
        //ym('reachGoal', 'send_number');
        handleNext();
    }

    return (
        <>
            <h1 className={styles.title}>
                <br/>&nbsp;&nbsp;Привет!
                <img className={styles.hiImage} alt='/' src={hi} />
            </h1>
            <p className={styles.description1}>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;Мы поможем тебе с домашкой!<br/>&nbsp;&nbsp;&nbsp;&nbsp;Во всем разберемся,<br/>&nbsp;&nbsp;&nbsp;&nbsp;сделаем непонятное понятным,<br/>&nbsp;&nbsp;&nbsp;&nbsp;а сложное - простым.
            </p>
            <div className={styles.inputBlock}>
                <div className={styles.form}><br/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <MaskInput
                        onChange={(value) => dispatch(setPhone(value))}
                        value={phone}
                        label="Номер телефона"
                        error={error}
                    />
                </div>
                <Button width={48} onClick={clickNext}>
                    <Arrow />
                </Button>
            </div>
            <span className={styles.greyText}><br/>&nbsp;&nbsp;&nbsp;&nbsp;Продолжая вы принимаете</span>
            <p className={styles.greyText}>
                <a href="https://app.izidz.com/documents/eula.pdf" target='_blank' className={styles.orangeText}><br/>&nbsp;&nbsp;&nbsp;&nbsp;Пользовательское соглашение</a> и <a href="https://app.izidz.com/documents/privacy-policy.pdf" target='_blank' className={styles.orangeText}><br/>&nbsp;&nbsp;&nbsp;&nbsp;Политику конфиденциальности</a>
            </p>
        </>
    );
}

export default FirstScreen;
